<template>
  <b-card-code title="Créer un nouveau Departement">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de Departement </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nom"
              label-for="Nom"
            >
              <validation-provider
                #default="{ errors }"
                name="Nom"
                rules="required"
              >
                <b-form-input
                  v-model="departement.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nom"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <h5>Etat département</h5>
              <b-form-checkbox
                v-model="departement.active"
                name="check-button"
                switch
                inline
                @click="departement.active = true"
              >
                Actif
              </b-form-checkbox>
            </b-form-group>

          </b-col>
          <b-col md="6">
            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click.prevent="addDepartement"
              >
                Ajouter
              </b-button>
              <b-button
                variant="outline-secondary"
                type="reset"
              >
                Réinitialiser
              </b-button>
            </b-col>
          </b-col></b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,

  },
  data() {
    return {
      password: '',
      repositories: [],
      departement: {
        name: '',
        active: false,
      },
      required,
    }
  },

  methods: {
    async addDepartement() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/departments/create/',
                {
                  name: this.departement.name,
                  active: this.departement.active,
                },
              )
            this.$router.push('/departements')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Departement ajouté avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
